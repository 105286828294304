setTimeout(() => {
    const whatsappButton = [].slice.call(document.getElementsByClassName('ButtonWhats'));
    const modalForms = [].slice.call(document.getElementsByClassName('modal-forms-container'))[0]
    const modalCloseButton = [].slice.call(document.getElementsByClassName('close-button'))[0]
    const phone = document.getElementById('phone')
    const inputs = [].slice.call(document.getElementsByClassName('input-forms'))
    const fieldsets = [].slice.call(document.getElementsByClassName('fieldset-forms'))
    const forms = document.getElementById('form_cards_institucional')
    const formsTitle = [].slice.call(document.getElementsByClassName('forms-title'))[0]
    const formsName = document.getElementById('name')
    const formsCidade = document.getElementById('cidade')
    let velocidadePlano = ''
    let precoPlano = ''


    whatsappButton.forEach(button => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            modalForms.style.display = 'flex'
            velocidadePlano = button.dataset.velocidade
            precoPlano = button.dataset.price

            formsTitle.innerHTML = 'Você escolheu o plano ' + velocidadePlano.replace('Mega', 'MEGA') + ' por R$ ' + precoPlano +'.'
        })
    })

    modalCloseButton.addEventListener('click', () => {
        modalForms.style.display = 'none'
    })

    // phone.addEventListener("keyup", () => {
    //     let telefone = phone.value.replace(/\D/g, '');

    //     if(phone.value.length > 2){
    //         telefone = '(' + telefone.substring(0, 2) + ') ' + telefone.substring(2, 11);
    //         phone.value = telefone;
    //     }

    // })

    inputs.forEach((item, index) => {
        item.addEventListener("focus", () => {
            fieldsets[index].classList.add('active')
            fieldsets[index].classList.remove('alert')
        })
        item.addEventListener("blur", () => {
            if(item.value != ''){
                fieldsets[index].classList.remove('alert')
            }else{
                fieldsets[index].classList.add('alert')
            }
        })
    })

    // if(forms != null) {
    //     forms.addEventListener('submit', (e) =>{
    //         e.preventDefault()

    //         const url = forms.dataset.link + "&text=Ol%C3%A1!%20Essas%20s%C3%A3o%20as%20minhas%20informa%C3%A7%C3%B5es%3A%0A" + formsName.value + '%0A' + phone.value + '%0A' + formsCidade.value + '%0A' + velocidadePlano + '%0AQuero%20concluir%20a%20minha%20compra%20por%20aqui.'


    //         modalForms.style.display = 'none'
    //         window.open(url, "_blank");
    //     })
    // }
  }, 2000);
